import { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Spinner } from 'react-bootstrap';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { BrukerWithTilgangerDto, UpdateBrukerCommand } from 'services/AdminportalenApi/BrukereApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import TsKundeTypeahead from 'components/TsKundeTypeahead';
import { format } from 'date-fns';
import { TsKundeSearchDto } from 'services/AdminportalenApi/TsKunderApi/types';
import TsKunderApi from 'services/AdminportalenApi/TsKunderApi';

import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import nb from 'react-phone-number-input/locale/nb.json'
import { E164Number } from 'libphonenumber-js/types.cjs';
interface Props {
  bruker: BrukerWithTilgangerDto;
  show: boolean;
  setShow: (value: boolean) => void;
  onUpdated: () => void;
}

const EditBruker = (props: Props) => {
  const toaster = useToaster();
  const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto>();

  const initialValues: UpdateBrukerCommand = {
    brukerId: props.bruker.brukerId,
    tsKundeId: props.bruker.tsKundeId,
    givenName: props.bruker.givenName,
    surname: props.bruker.surname,
    jobTitle: props.bruker.jobTitle,
    mobile: props.bruker.mobile,
    otherMail: props.bruker.otherMail,
    jobFunctions: props.bruker.jobFunctions
  }

  const validate = (values: UpdateBrukerCommand) => {
    let errors: any = {};
    if (!values.givenName) {
      errors.givenName = 'Fornavn kan ikke være tomt.';
    }
    if (!values.surname) {
      errors.surname = 'Etternavn kan ikke være tomt.';
    }
    // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.otherMail)){
    //   errors.otherMail = 'Alternativ e-post har ugyldig format';
    // }
    return errors;
  }

  const onSubmit = async (command: UpdateBrukerCommand) => {
    try {
      await BrukereApi.updateBruker(command);
      toaster.success('Suksess', 'Bruker opprettet!');
      props.setShow(false);
      resetForm(undefined);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);


  useEffect(() => {
    if (props.bruker.tsKundeId) {
      TsKunderApi.getById(props.bruker.tsKundeId).then(response => setSelectedTsKunde(response));
    } else {
      setSelectedTsKunde(prev => null);
    }
  }, [props.bruker.tsKundeId])

  return (
    <Modal size="xl" centered show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{props.bruker.givenName} {props.bruker.surname}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>E-post</Form.Label>
                <Form.Control type="text" value={props.bruker.email} readOnly />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Fornavn</Form.Label>
                <Form.Control type="text" value={values.givenName} onChange={e => handleChange('givenName', e.target.value)} isInvalid={errors?.givenName && touched?.givenName} />
                <Form.Control.Feedback type="invalid">{errors?.givenName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Etternavn</Form.Label>
                <Form.Control type="text" value={values.surname} onChange={e => handleChange('surname', e.target.value)} isInvalid={errors?.surname && touched?.surname} />
                <Form.Control.Feedback type="invalid">{errors?.surname}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Ansatt hos TsKunde</Form.Label>
                <TsKundeTypeahead selectedTsKundeId={selectedTsKunde?.tsKundeId}
                  onHandleChange={e => handleChange('tsKundeId', e?.tsKundeId)} />
                {errors?.tsKundeId && touched?.tsKundeId && <div className={Style.invalidFeedback}>{errors?.tsKundeId}</div>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Alternativ e-post</Form.Label>
                <Form.Control type="text" value={values.otherMail} onChange={e => handleChange('otherMail', e.target.value)} />
                {values.otherMail === props.bruker.email && (
                  <Form.Label>Er det virkelig nødvendig med en alternativ e-post som er helt lik e-post?</Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
              <Form.Label>Telefon</Form.Label>
              <PhoneInputWithCountrySelect 
                  labels={nb} 
                  countryOptionsOrder={['NO', 'SE', 'DK']} 
                  defaultCountry='NO' 
                  className='form-control' 
                  value={values.mobile} 
                  onChange={(num: E164Number) => handleChange('mobile', num)} />
              <Form.Control.Feedback type="invalid">
                {errors?.mobile}
              </Form.Control.Feedback>
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3">
                <Form.Label>Tittel</Form.Label>
                <Form.Control type="text" value={values.jobTitle} onChange={e => handleChange('jobTitle', e.target.value)} isInvalid={errors?.jobTitle && touched?.jobTitle} />
                <Form.Control.Feedback type="invalid">{errors?.jobTitle}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              {props.bruker.opprettet && (
                <Form.Group>
                  <Form.Label>
                    Opprettet: {format(props.bruker.opprettet, 'dd.MM.yyyy')}
                  </Form.Label>
                </Form.Group>
              )}
            </Col>
            <Col style={{ textAlign: 'end' }}>
              <Form.Group>
                <Form.Label >
                  ObjectId: {props.bruker.objectId}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>

          <IconButton icon="close" className='col' variant="outline-primary" onClick={() => props.setShow(false)}>
            Avbryt
          </IconButton>

          <IconButton
            icon='disk'
            variant="primary"
            className='col'
            disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            type="submit">
            Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </IconButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditBruker;